.profile-card .card-body{
  position:relative;
}

.profile-card .profile {
border-radius: 50%;
margin-left: 5%;
max-width: 100%;
border: 0px /* Replace default border style */;
-webkit-transform: translate(-0%, 0%);
transform: translate(-0%, 0%);
}

.profile-card{
  background-color: #fff;
  color:#000;
  min-width: 30%;
}

.profile-card h5{
  font-weight:600;
  color:#000;
}

.profile-card p{
  color:#000;
  line-height: 5px;
}

.profile-card .card-text{
  font-weight:300;
  font-size:15px;
}

.profile-card .image-change-text {
  font-size:0.8rem;
}

.profile-card .icon-block{
  float:left;
  width:100%;
}

.profile-card .icon-block a{
  text-decoration:none;
}

.profile-card i {
  display: inline-block;
  font-size: 16px;
  color: #000;
  text-align: center;
  height: 20px;
  border-radius: 50%;
  margin:0 5px;
}

.profile-card i:hover {
  color: var(--accent-color);
}

[data-initials]:before {
  background: var(--accent-color);
  color: white;
  opacity: 1; 
  content: attr(data-initials); 
  display: inline-block; 
  font-weight: bold; 
  border-radius: 50%; 
  vertical-align: middle; 
  margin-right: 0.5em; 
  width: 150px; 
  height: 150px; 
  line-height: 150px; 
  text-align: center; 
  }
  