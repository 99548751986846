.action-buttons-row-expansive-custom-width {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
}

@media screen and (max-width: 433px) {
  .action-buttons-row-expansive-custom-width {
    width: 100%;
  }
}

.user-profile-picture-container {
  min-width: 96.13px;
  width: 96.13px;
}

.custom-userName-header-width {
  width: 15%;
}

.p-multiselect-label-container {
  width: 10px;
}