.p-steps .p-component {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #007ad9;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  height: 65px;
}

.input-password-container {
  max-width: 440px;
}

.action-buttons-container {
  width: 119px;
}

.p-steps-title {
  color: rgba(0, 0, 0, 0.9) !important;
}

.p-steps .p-steps-item:before {
  border-color: #959595;
}

.full-width-password {
  width: 100%;
}
.forgotPasswordPage .specialInputs {
  height: 50px;
}

.forgotPasswordPage .negateMarginTop {
  margin-top: -26px;
}