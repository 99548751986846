.inverted {
  filter: invert(1);
}

.fullSizePreview.image {
  width: 100%;
  height: auto;
}

/** Applying styles to div. */
.image-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; 
  height: 50px; 
}