.registerForm-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.registerForm, .verificationForm {
  width: 470px;
}

.registerForm .p-card, .verificationForm .p-card {
  border-radius: var(--primary-border-radius);
  background-color: #fff;
  margin-top: 3em;
}

.verificationForm .p-card .p-card-body {
  border: 4px solid #000;
  border-radius: var(--primary-border-radius);
}

.registerForm .negateMarginTop {
  margin-top: -24px;
}

.registerForm .specialInputs {
  height: 50px;
}

.registerForm .form-control {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.registerForm .form-control:focus {
  z-index: 2;
}

/* In case of any background color, keep it white  */
.registerForm input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.registerForm .checkbox-container {
  display: table-cell;
}

.registerForm .checkbox {
  display: inline-block;
  vertical-align: middle;
}

.registerForm .logoImage, .externalApplication-ul .logoImage {
  width: 100%;
  object-fit: contain;
  max-height: 150px;
}

hr {
  width: 90%;
}

.p-invalid {
  margin-top: 0;
}

.registerForm p {
  text-align: center;
  font-size: 0.8em;
}

.registerForm p:first-child {
  font-size: 1.2em;
}

.registerForm .btn-outline-primary {
  border: none !important;
}

.registerForm button#btn-password {
  border-radius: 8px !important;
  border: 1px solid #ced4da !important;
}

.registerForm .btn-outline-primary:focus {
  box-shadow: none !important;
}

.form-control {
  border-radius: var(--primary-border-radius) !important;
}

.verificationForm .p-card-content {
  padding: 15px;
}

.custom-margin {
  margin-bottom: 15px;
}

.terms-checkbox .p-checkbox-box.p-highlight {
  border-color: #007ad9;
  background: #007ad9;
}

.terms-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #005b9f;
  background: #005b9f;
}

.externalApplication-ul {
  list-style-type: none;
  padding: 20px;
  border-radius: 10px;
  background-color: #2f2f2f;
  color: var(--text-color);
  margin-top: 17em;
}
.margin-adjust {
  margin: 0 auto;
}

@media screen and (max-width: 909px) {
  .externalApplication-ul {
    margin-top: 3em;
  }
}
.invert-logo-background {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

@media screen and (max-width: 539px) {
  .externalApplication-registerForm {
    flex: 0 0 92.5%;
    max-width: 92.5%;
  }
}