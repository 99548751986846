/**
* Global.css (abstractuser) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Sai Charan K, 2020 
* @file Global.css
* @author Sai Charan K
* @copyright 2020 InstaMaterial GmbH. All rights reserved.
* @section License
*/

@import url('~bootstrap/dist/css/bootstrap.min.css');
@import url('~bootstrap-icons/font/bootstrap-icons.css');
@import url('~primereact/resources/primereact.min.css');
@import url('~primeflex/primeflex.css');
@import url('~primeicons/primeicons.css');
@import url('~primereact/resources/themes/nova-accent/theme.css');

:root {
  --accent-color: #DC0900;
  --hover-accent-color: #9f0000;
  --selection-color-highlighted: #ffffff;
  --table-row-hover-color: #e0f3ff;
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
    Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
}

html {
  height: 100%;
}

body {
  font-size: 1em;
  line-height: 1.2;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  display: flex;
  color: var(--primary-background-color);
}

body [class*='col-'] {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

#root {
  width: 100%;
}

.App {
  overflow: hidden;
}

body .p-inputtext,
body .p-dropdown,
body .p-multiselect {
  width: 100%;
  line-height: 1.5rem;
}

.p-progress-spinner {
  width: 50px;
  height: 50px;
}

.p-dialog {
  border: none;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  text-shadow: none;
  text-align: left;
  font-weight: bold !important;
}

h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 18px;
}

input[type='file']:hover,
input[type='file']::-webkit-file-upload-button:hover {
  cursor: pointer;
  color: #000000;
}

.p-component,
.p-link,
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input,
.p-chips .p-chips-multiple-container .p-chips-input-token input,
.p-inputtext,
.p-terminal .p-terminal-input {
  font-family: var(--font-family);
  font-size: 16px;
}

.p-tieredmenu .p-menu-separator {
  margin: 10px 0px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: var(--primary-control-color);
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-control-color);
  border-color: var(--primary-control-color);
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
  background: #007ad9;
}

.insta-image-favicon-container {
  position: relative;
  width: -webkit-fit-content;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: center;
}

.previewTemplateContainer,
.previewHeadingContainer {
  height: 300px;
}

.forgotPasswordPage {
  margin: auto;
  min-height: 100vh;
  max-width: 75rem;
}

.forgotPasswordContainer {
  padding: 0.5rem;
}

.signUpContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}

@media (max-width: 575px) {
  .p-toast {
    width: 20rem;
  }
}

@media (min-width: 576px) {
  .p-dialog {
    width: 75%;
  }
}

@media (min-width: 992px) {
  .p-dialog {
    width: 50%;
  }
}

#confirmPopup .popover-header {
  background-color: var(--primary-background-color);
  color: #fff;
  font-size: 14px;
  font-family: var(--font-family);
}

#confirmPopup .popover-body {
  font-size: 14px;
  font-family: var(--font-family);
}

#confirmPopup button {
  line-height: 1rem;
}

#confirmationDialog {
  width: 350px;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.colWidth__120_const {
  width: 120px;
}

.profileCard .p-card-body,
.profileCard .p-card-content {
  padding-top: 0px;
}

.activationContainer {
  height: 100vh;
}

/*password reset title*/
.passwordResetTitle {
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.instaIcon::before {
  line-height: initial;
}

.multiselect .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect .country-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  /* next two vars are from primereact nova accent theme */
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect .country-item-value img.flag {
  width: 17px;
}

.p-menuitem {
  z-index: 10;
}

.loaderInHeader {
  display: inline-block;
  float: right;
  margin-top: -4px;
}

/* input text number without arrows: Chrome, Safari, Edge, Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input text number without arrows: Firefox */
.input-number input[type='number'] {
  -moz-appearance: textfield;
}

/* Date range selector. */
.date-picker-container {
  z-index: 12;
  position: absolute;
  left: 15px;
  top: 50px;
}

/* Adding asterisk to required fields. */
.required:after {
  content: ' *';
  color: red;
}
.CodeMirror {
  height: 100vh !important;
}

/* tab view panel */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-weight: bold;
  color: var(--primary-background-color);
  background: transparent;
  border: 0px;
}

.p-tabview .p-tabview-nav li {
  margin-right: 1.3rem;
}
@media (max-width: 575px) {
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  color: #ff007c;
  border: 0px;
  border-bottom: 5px solid #ff007c;
}

.p-tabview .p-tabview-panels {
  border: 0px;
  border-top: 2px solid #e9e7e7;
  padding-left: 0;
  padding-right: 0;
}

.p-tabview .p-tabview-panels .p-tabview-panel {
  margin-top: 2rem;
}

.border-radius-10 {
  border-radius: 10px;
}

.p-datatable-thead tr th>span button i {
  margin-right: 0 !important;
}

.col-dropdown .p-inputtext,
.col-dropdown .p-dropdown-trigger {
  color: var(--white) !important;
}

.p-dialog .p-dialog-footer {
  border: none;
}

.is-valid, .valid-feedback, .invalid-feedback {
  display: block;
  visibility: visible;
}

/** Assign color to My Account nav bar menus */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  border: 0;
  border-bottom: 5px solid;
  color: var(--primary-control-color);
}

/* Overwrite login-pages-global-container class defined in AWC/Client/common.js */
.login-pages-global-container .p-card {
  border-radius: var(--primary-border-radius);
}

.p-panelmenu .template-editor {
  transition: 0.60s ease-out;
}
.info-icon {
  color: #0069d9 !important;
}

.btn-primary:hover:not([disabled]) {
  background-color: var(--primary-control-hover-color) !important;
  border: 1px solid var(--primary-control-hover-color) !important;
}

.btn-primary {
  background-color: var(--primary-control-color) !important;
  border: 1px solid var(--primary-control-color) !important;
}